import {
	getMetaData,
	getUniversityProgramPageMetaData,
} from '@/utils/deserializer/universityPage';
import get from 'lodash/get';

//actions
import { getCMSData, getUniversities, getUniversityData } from '@/api';
import { getUniversityCardData } from '@/utils/deserializer/universityPage/util';
import { blogPageListAction } from './blogPage';

export const universityListAction = async ({
	page = 1,
	per_page = 20,
	q,
	country,
	type = [],
	category = [],
}) => {
	const query = { page, per_page };

	if (q) query.q = q;

	if (country) query.country = country;

	if (type.length > 0) query.type = type;

	if (category.length > 0) query.category = category;

	const response = await getUniversities(query);
	return {
		data: response.data.map(getUniversityCardData),
		meta: {
			pagination: {
				page: response.headers.page,
				pageSize: response.headers.per_page,
				pageCount: response.headers.total_pages,
				total: response.headers.total_count,
			},
		},
	};
};

export const universityFormAction = async () => {
	const result = await getCMSData('generic-forms', {
		filters: { name: { $eq: 'University Form' } },
		populate: 'deep',
	});
	return get(result, 'data.data[0]', null);
};

export const universityPageAction = async (universitySlug) => {
	const [result, form] = await Promise.all([
		getUniversityData(universitySlug),
		universityFormAction(),
	]);

	if (!get(result, 'data')) return null;
	if (form) result.data.form = { data: form };

	if (get(result, 'data.location.country')) {
		const [blogs] = await Promise.all([
			blogPageListAction({
				page: 1,
				pageSize: 3,
				countries: [result.data.location.country],
			}),
		]);

		result.data.blogs = blogs.data;
	}

	result.data.meta = getMetaData(
		get(result, 'data.name'),
		get(result, 'data.images.logo.original')
	);

	return result.data;
};

export const universityProgramPageAction = async (universitySlug) => {
	const [result, form] = await Promise.all([
		getUniversityData(universitySlug),
		universityFormAction(),
	]);

	if (!get(result, 'data')) return null;
	if (form) result.data.form = { data: form };

	result.data.meta = getUniversityProgramPageMetaData(
		get(result, 'data.name'),
		get(result, 'data.images.logo.original')
	);

	return result.data;
};

export const universityListPageAction = async () => {
	const form = await universityFormAction();
	return form ? { _id: 1, form: { data: form } } : { _id: 1 };
};
