import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';

// actions
import { getAllForCollection, getCMSData } from '@/api/cms';
import { eventListSitemapAction } from './eventPage';

// utils
import getPlatform from '@/utils/getPlatform';
import { ID } from '@/utils/uniqueID';

// constants
import { getUniversitySlugs } from '@/api';
import constants from '@/constants';
import { pageTypes } from '@/utils/constants/pageTypes';
const baseUrl = constants.seo.sitemapBaseUrl,
	platform = getPlatform(),
	id = ID(),
	config = {
		[pageTypes.country]: {
			pagePath: '/country/',
			title: 'COUNTRIES',
			id,
		},
		[pageTypes.event]: {
			pagePath: '/events-webinars/',
			title: 'EVENTS & WEBINARS',
			id: id + 1,
		},
		[pageTypes.blog]: {
			pagePath: '/expert-tips/',
			title: 'BLOGS',
			id: id + 2,
		},
		[pageTypes.landing]: {
			pagePath: '/page/',
			title: 'LANDING PAGES',
			id: id + 3,
		},
		[pageTypes.product]: {
			pagePath: '/product/',
			title: 'PRODUCTS',
			id: id + 4,
		},
		[pageTypes.program]: {
			pagePath: '/program/',
			title: 'PROGRAMS',
			id: id + 5,
		},
		[pageTypes.courseFinderUniversityDetail]: {
			pagePath: '/course-finder/universities/',
			title: 'UNIVERSITIES',
			id: id + 6,
		},
		[pageTypes.webStories]: {
			pagePath: '/web-stories/',
			title: 'WEB STORIES',
			id: id + 7,
		},
		[pageTypes.counsellor]: {
			pagePath: '/counsellors/',
			title: 'COUNSELLORS',
			id: id + 8,
		},
		others: {
			pagePath: '/',
			title: 'OTHERS',
			id: id + 9,
		},
	};

const getPageEntry = (data, pageType) => {
	const pageTypeConfig = config[pageType],
		items = data.map((item) => {
			const attributes = item.attributes || item;

			return {
				loc: `${baseUrl}${pageTypeConfig.pagePath}${
					attributes.slug === 'home-page' ? '' : attributes.slug
				}`,
				label:
					attributes.title ||
					capitalize(attributes.slug.replaceAll('-', ' ')) ||
					'',
			};
		});

	return { id: pageTypeConfig.id, title: pageTypeConfig.title, items };
};

export const sitemapPageAction = async () => {
	const indexFilter = {
		filters: {
			$or: [{ noIndex: false }, { noIndex: { $null: true } }],
		},
	};

	let pageActions;
	switch (platform) {
		case 'ielts': {
			pageActions = [
				getAllForCollection('product-pages', indexFilter).then((data) =>
					getPageEntry(data, pageTypes.product)
				),
				getCMSData('pages', indexFilter).then((res) =>
					getPageEntry(res.data.data, 'others')
				),
			];
			break;
		}
		case 'partner': {
			pageActions = [
				getCMSData('country-pages', indexFilter).then((res) =>
					getPageEntry(res.data.data, pageTypes.country)
				),
				getAllForCollection('landing-pages', indexFilter).then((data) =>
					getPageEntry(data, pageTypes.landing)
				),
				getCMSData('pages', indexFilter).then((res) =>
					getPageEntry(res.data.data, 'others')
				),
			];
			break;
		}
		default: {
			pageActions = [
				getCMSData('country-pages', indexFilter).then((res) =>
					getPageEntry(res.data.data, pageTypes.country)
				),
				eventListSitemapAction().then((res) =>
					getPageEntry(res.data, pageTypes.event)
				),
				getAllForCollection('blog-pages', indexFilter).then((data) =>
					getPageEntry(data, pageTypes.blog)
				),
				getAllForCollection('product-pages', indexFilter).then((data) =>
					getPageEntry(data, pageTypes.product)
				),
				getAllForCollection('landing-pages', indexFilter).then((data) =>
					getPageEntry(data, pageTypes.landing)
				),
				getAllForCollection('program-pages', indexFilter).then((data) =>
					getPageEntry(data, pageTypes.program)
				),
				getUniversitySlugs({ is_published: true }).then((res) =>
					getPageEntry(res.data, pageTypes.courseFinderUniversityDetail)
				),
				getAllForCollection('web-stories').then((data) =>
					getPageEntry(data, pageTypes.webStories)
				),
				getAllForCollection('counsellor-pages').then((data) =>
					getPageEntry(data, pageTypes.counsellor)
				),
				getCMSData('pages', indexFilter).then((res) => {
					const pageEntry = getPageEntry(res.data.data, 'others');
					pageEntry.items.push(
						{ loc: baseUrl + '/course-finder', label: 'Course Finder Page' },
						{ loc: baseUrl + '/scholarships', label: 'Scholarships' }
					);
					return pageEntry;
				}),
			];
			break;
		}
	}

	const pages = await Promise.all(pageActions);

	return { data: pages.filter((page) => !isEmpty(page.items)) };
};
