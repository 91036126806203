import get from 'lodash/get';

import { getCMSData } from '@/api';
import { counsellorFilterForm } from '@/utils/constants/formTypes';

const shapeImage = (image) => {
	if (get(image, 'data.attributes.url')) {
		return {
			url: image.data.attributes.url,
			alternativeText: image.data.attributes.alternativeText,
		};
	}

	return null;
};

const shapeCouncellorData = (data) => {
	let result = data.attributes;

	result.id = data.id;
	result.visasCount = `${data.attributes.visasCount}+`;
	result.profileImage = shapeImage(get(data, 'attributes.profileImage'));
	result.centre = get(data, 'attributes.centre.data.attributes') || null;

	if (get(data, 'attributes.destinations.data')) {
		result.destinations = data.attributes.destinations.data.map(
			({ attributes }) => ({
				...attributes,
				image: shapeImage(attributes.image),
			})
		);
	}
	if (get(data, 'attributes.specialities.data')) {
		result.specialities = data.attributes.specialities.data.map(
			({ attributes }) => attributes
		);
	}
	if (get(data, 'attributes.languages.data')) {
		result.languages = data.attributes.languages.data.map(
			({ attributes }) => attributes
		);
	}
	if (get(data, 'attributes.topUniversities.data')) {
		result.topUniversities = data.attributes.topUniversities.data.map(
			({ attributes }) => attributes
		);
	}
	if (get(data, 'attributes.testimonials')) {
		result.testimonials = data.attributes.testimonials.map((testimonal) => ({
			...testimonal,
			university: get(testimonal, 'university.data.attributes') || null,
			image: shapeImage(testimonal.image),
		}));
	}

	return result;
};

const shapeCouncellorListData = (data, counsellorsData) => {
	const ctaHeroSection = {
		heroImage: {
			url: 'https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/Counselor_background_7859382cbc.png',
			alternativeText: 'bg-image',
		},
		heroLogo: shapeImage(data.attributes.logo),
		mainTitle: data.attributes.title,
		mainDescription: data.attributes.description,
		tagLine: data.attributes.tagLine,
		tagLineDescription: data.attributes.tagLineDescription,
		buttonText: data.attributes.buttonText,
	};

	return {
		...data.attributes,
		ctaHeroSection,
		filterForm: counsellorFilterForm,
		listSection: {
			itemsInMobile: 1,
			itemsInTablet: 2,
			itemsInDesktop: 3,
			noCounsellorsText: data.attributes.noCounsellorsText,
		},
		counsellorsData,
	};
};

export const counsellorListAction = async (
	pageSize = 6,
	page = 1,
	filters = {}
) => {
	const counsellorListProps = { data: [], page: 1, total: 0 },
		query = {
			populate: 'deep',
			pagination: { page, pageSize },
			sort: ['visasCount:desc', 'createdAt:desc'],
		};

	if (get(filters, 'city') || get(filters, 'target_country')) {
		query.filters = {};

		if (get(filters, 'city')) {
			query.filters.centre = Array.isArray(filters.city)
				? { name: { $in: filters.city } }
				: { name: { $eq: filters.city } };
		}
		if (get(filters, 'target_country')) {
			query.filters.destinations = Array.isArray(filters.target_country)
				? { name: { $in: filters.target_country } }
				: { name: { $eq: filters.target_country } };
		}
	}

	const result = await getCMSData('counsellor-pages', query);
	counsellorListProps.data = result.data.data.map(shapeCouncellorData);
	counsellorListProps.page = result.data.meta.pagination.page;
	counsellorListProps.total = result.data.meta.pagination.total;
	return counsellorListProps;
};

export const counsellorListPageAction = async (filters = {}) => {
	const [result, counsellorsData] = await Promise.all([
		getCMSData('counsellor-list-page', { populate: '*' }),
		counsellorListAction(6, 0, filters),
	]);

	return get(result, 'data.data')
		? shapeCouncellorListData(result.data.data, counsellorsData)
		: null;
};

export const counsellorPageAction = async (slug) => {
	const result = await getCMSData('counsellor-pages', {
		filters: { slug: { $eq: slug } },
		populate: 'deep',
	});

	return get(result, 'data.data[0]')
		? shapeCouncellorData(result.data.data[0])
		: null;
};
