import get from 'lodash/get';

// apis
import { getCMSData } from '@/api';
import { blogPageListAction } from './blogPage';

const shapeResponse = (data) => {
	if (!data) return null;

	data.form = get(data, 'form.data.attributes', null);
	data.centre = get(data, 'centre.data.attributes');

	return data;
};

const shapeBlogIndexPageResponse = (result) => {
	const data = { data: [] };

	if (get(result, 'data')) {
		data.data = result.data.map(({ attributes }) => ({
			...attributes,
			link: `/kiosk/expert-tips/${attributes.slug}`,
			image: {
				url: get(attributes, 'image.data.attributes.url', ''),
				alternativeText: get(
					attributes,
					'image.data.attributes.alternativeText',
					''
				),
			},
		}));
	}
	return data;
};

export const kioskCenterPageAction = async (slug) => {
	const result = await getCMSData('kiosk-pages', {
		filters: { centre: { slug: { $eq: slug } } },
		populate: [
			'form.successModal.successModalButtons',
			'form.errorModal',
			'centre',
		],
	});

	return shapeResponse(get(result, 'data.data[0].attributes'));
};

export const kioskBlogIndexPageAction = async () => {
	const result = await blogPageListAction({ page: 1, pageSize: 12 });
	return shapeBlogIndexPageResponse(result);
};
