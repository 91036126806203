import get from 'lodash/get';

//actions
import { getCMSData, getScholarship, getScholarships } from '@/api';

//meta
import {
	getMetaData,
	getScholarshipDetailsMetaData,
} from '@/utils/deserializer/scholarshipPage';

//deserializer
import { getScholarshipCardData } from '@/utils/deserializer/scholarshipPage/util';

export const scholarshipListAction = async ({
	page = 1,
	per_page = 15,
	q,
	level = [],
	countries = [],
	universities = [],
}) => {
	const query = { page, per_page };
	if (q) query.q = q;

	query.level = level;

	if (countries.length > 0) query.countries = countries;

	if (universities.length > 0) query.universities = universities;

	const response = await getScholarships(query);

	return {
		data:
			response && response.data && response.data.map(getScholarshipCardData),
		meta: {
			pagination: {
				page: response.headers.page,
				pageSize: response.headers.per_page,
				pageCount: response.headers.total_pages,
				total: response.headers.total_count,
			},
		},
	};
};

export const scholarshipPageAction = async (pageSlug) => {
	const [result, form] = await Promise.all([
		getScholarship(pageSlug),
		scholarshipFormAction(),
	]);
	if (!get(result, 'data')) return null;
	if (form) result.data.form = { data: form };
	result.data.meta = getScholarshipDetailsMetaData(
		get(result, 'data.name'),
		get(result, 'data.images.logo.original')
	);

	return result.data;
};

export const scholarshipFormAction = async () => {
	const result = await getCMSData('generic-forms', {
		filters: { name: { $eq: 'University Form' } },
		populate: 'deep',
	});
	return get(result, 'data.data[0]', null);
};

export const scholarshipListPageAction = async () => {
	const form = await scholarshipFormAction();
	const scholarshipMeta = getMetaData();
	return form
		? { _id: 1, form: { data: form }, meta: scholarshipMeta }
		: { _id: 1, meta: scholarshipMeta };
};
