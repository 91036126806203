// Api
import { getCMSData } from '@/api';
// Defaults
import get from 'lodash/get';
import moment from 'moment-mini';
// utils

export const eventListSitemapAction = async (page = 1, pageSize = 100) => {
	const result = await getCMSData('event-pages', {
		filters: {
			$and: [
				{ showOnListingPage: true },
				{ $or: [{ noIndex: false }, { noIndex: { $null: true } }] },
			],
		},
		sort: ['dateTime:desc'],
		pagination: { page, pageSize },
	});
	return result.data;
};

export const eventPageListAction = async ({
	page = 1,
	pageSize = 20,
	tagFilterList = [],
	dayFilter,
	pastEvent,
	countries = [],
	tags = [],
	categories = [],
	exclude,
	searchQueryValues = {},
}) => {
	const andFilterArray = [{ showOnListingPage: true }];
	if (exclude) {
		andFilterArray.push({ slug: { $ne: exclude } });
	}
	if (countries.length > 0) {
		andFilterArray.push({ locations: { name: { $in: countries } } });
	}
	if (tags.length > 0) {
		andFilterArray.push({ tags: { name: { $in: tags } } });
	}
	if (categories.length > 0) {
		andFilterArray.push({ categories: { name: { $in: categories } } });
	}

	if (searchQueryValues.utm_category) {
		andFilterArray.push({
			$or: [
				{ tags: { name: { $eqi: searchQueryValues.utm_category } } },
				{ categories: { name: { $eqi: searchQueryValues.utm_category } } },
				{ location: { name: { $eqi: searchQueryValues.utm_category } } },
			],
		});
	}

	if (dayFilter || !pastEvent) {
		const date = moment();
		let dateTimeFilter = { endTime: {} };
		if (!pastEvent) {
			dateTimeFilter['endTime']['$gt'] = date.toISOString();
		}
		switch (dayFilter) {
			case 'week':
				dateTimeFilter['endTime']['$lt'] = date.endOf('week').toISOString();
				break;
			case 'month':
				dateTimeFilter['endTime']['$lt'] = date.endOf('month').toISOString();
				break;
		}
		andFilterArray.push(dateTimeFilter);
	}
	if (tagFilterList.length > 0) {
		andFilterArray.push({
			$or: [
				{ tags: { name: { $in: tagFilterList } } },
				{ categories: { name: { $in: tagFilterList } } },
				{ location: { name: { $in: tagFilterList } } },
			],
		});
	}
	let result = await getCMSData('event-pages', {
		filters: { $and: andFilterArray },
		populate: ['location', 'image'],
		sort: ['dateTime:asc'],
		pagination: { page, pageSize },
	});
	return result.data;
};

export const eventPageAction = async (slug) => {
	let result = await getCMSData('event-pages', {
		filters: { slug: { $eq: slug } },
		populate: 'deep',
		shapeListResponse: 'true',
	});

	return get(result, 'data.data[0]', null);
};

export const getNextUpcomingEventAction = async () => {
	const result = await getCMSData('event-pages', {
		filters: {
			endTime: { $gt: moment().toISOString() },
			showOnListingPage: true,
		},
		pagination: { page: 1, pageSize: 1 },
		populate: ['image'],
		sort: ['dateTime:asc'],
	});

	return get(result, 'data.data[0].attributes', null);
};
