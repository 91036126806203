//actions
import { getCMSData } from '@/api';

// utils
import get from 'lodash/get';

export const blogPageListAction = async ({
	page = 1,
	pageSize = 20,
	tagFilterList = [],
	searchText,
	countries = [],
	tags = [],
	categories = [],
	exclude,
	searchQueryValues = {},
}) => {
	const andFilterArray = [];
	if (exclude) {
		andFilterArray.push({ slug: { $ne: exclude } });
	}
	if (countries.length > 0) {
		andFilterArray.push({ locations: { name: { $in: countries } } });
	}
	if (tags.length > 0) {
		andFilterArray.push({ tags: { name: { $in: tags } } });
	}
	if (categories.length > 0) {
		andFilterArray.push({ categories: { name: { $in: categories } } });
	}

	if (searchQueryValues.category) {
		andFilterArray.push({
			$or: [
				{ tags: { name: { $eqi: searchQueryValues.category } } },
				{ categories: { name: { $eqi: searchQueryValues.category } } },
				{ locations: { name: { $eqi: searchQueryValues.category } } },
			],
		});
	}

	if (searchText) {
		andFilterArray.push({
			$or: [
				{ title: { $containsi: searchText } },
				{ categories: { name: { $containsi: searchText } } },
			],
		});
	}
	if (tagFilterList.length > 0) {
		andFilterArray.push({
			$or: [
				{ tags: { name: { $in: tagFilterList } } },
				{ categories: { name: { $in: tagFilterList } } },
				{ locations: { name: { $in: tagFilterList } } },
			],
		});
	}
	let result = await getCMSData('blog-pages', {
		filters: { $and: andFilterArray },
		populate: {
			image: '*',
			categories: { fields: ['name', 'slug'] },
		},
		sort: ['publishedAt:desc'],
		pagination: { page, pageSize },
	});
	return result.data;
};

export const blogPageAction = async (slug) => {
	let result = await getCMSData('blog-pages', {
		filters: { slug: { $eq: slug } },
		populate: 'deep',
		shapeListResponse: 'true',
	});

	return get(result, 'data.data[0]', null);
};
